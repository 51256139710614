import { useMemo } from "react";
import useContentManager from "./useContentManager";

export default (sessionId, courseTitle) => {
  const { getFilteredClasses } = useContentManager();

  return useMemo(
    () =>
      getFilteredClasses({
        config: {
          classFilters: [
            {
              filter: {
                sessionId,
              },
            },
          ],
          includeCurrentGuide: true,
        },
      })?.[courseTitle],
    [courseTitle]
  );
};
