import React, { useEffect, useState } from "react";
import MainSliderContainer from "../../components/classes/MainSliderContainer";
import { flatten, flattenDeep, keys, orderBy, sample, values } from "lodash";
import { Box } from "../../elements/Box";
import { Input } from "../../elements/Input";
import { Image } from "../../elements/Image";
import { BRAND } from "../../utils/assets";
import { Text } from "../../elements/Text";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { Button } from "../../elements/Button";

import CustomLoader from "../../components/CustomLoader";
import useContentManager from "../../hooks/useContentManager";
import ClassesGridView, { CLASS_FILTERS } from "../../components/classes/ClassesGridView";
import { MAIN_CLASSES_SCREEN } from "../../components/LEANTour";
import LEANTour from "../../components/LEANTour";
import { GUIDE_FIND_WORKOUT_FREQUENCY } from "../../config";

const RANDOM_SEARCH_PLACEHOLDERS = [
  "Back and neck pain",
  "How to perform a perfect Deadlift",
  "Do I need protein powder?",
  "How do I use my LEAN HIIT band?",
];

let searchDelayTimeout = null;
const ClassesPage = () => {
  const [searchQuery, setSearchQuery] = useState(null);

  const { allClasses, searchAllClasses, getFilteredClasses, guides, searching, loading } = useContentManager();

  /*
    As currently the data filtering etc is happening on the frontend (will move to backend),
    it is fairly resource intensive and takes a second before the UI updates with the newly
    filtered data.
    - therefore showing the loader is equivalent to tucking your mess under the rug
    */
  const [classFilterUpdating, setClassFilterUpdating] = useState(false);

  const showLoader = searching || loading || classFilterUpdating;
  const [filteredAllClasses, setFilteredAllClasses] = useState([]);
  const [classFilters, setClassFilters] = useState(CLASS_FILTERS);
  const updateClassFilters = (data) => {
    // setClassFilterUpdating(true);
    setClassFilters(data || []);
  };

  // useEffect(() => {
  //     setTimeout(() => {
  //         setClassFilterUpdating(false);
  //     }, 250);
  // }, [classFilterUpdating]);

  const guideData = guides?.map((guide) => ({
    productId: guide?.id,
    courseTitle: "Guides",
    subCategory: "",
    classTitle: "",
    asset: {
      thumbnail: guide?.meta_data?.guidePortraitImage,
    },
  }));

  // This handles all filtering: tags + search queries
  useEffect(() => {
    searchDelayTimeout = setTimeout(() => {
      if (searchDelayTimeout) clearTimeout();

      // First checking if we need to filter by search query or return all
      let searchFilteredData = allClasses;

      if (!!searchQuery) {
        searchFilteredData = searchAllClasses(searchQuery);
      }

      // Now we filter by class filter tags
      const filteredClasses = getFilteredClasses({
        config: {
          source: searchFilteredData,
          classFilters,
          includeCurrentGuide: true,
        },
      });

      setFilteredAllClasses(filteredClasses);
    }, 250);
  }, [loading, allClasses, searchQuery, classFilters]);

  const totalFilteredClassesResults = flattenDeep(values(values(filteredAllClasses))?.map(values));

  return (
    <>
      <LEANTour screen={MAIN_CLASSES_SCREEN} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        backgroundColor="darkTeal"
        padding={"4rem 0"}
        marginBottom="2rem"
      >
        <Box display="flex" flexDirection="row" justifyContent="center" color="white" marginBottom=".5rem">
          <Text fontSize={28} fontWeight="600">{`Search any`}</Text>
          <Image src={BRAND.logoWhite} width="5rem" height="auto" margin="0 1rem" alt={BRAND.name} />{" "}
          <Text fontSize={28} fontWeight="600">{` class...`}</Text>
        </Box>
        <Text fontSize={18} fontWeight="300" marginBottom="2rem" marginTop="2rem" color="white" paddingLeft="2rem" paddingRight="2rem" textAlign="center">
          {`Watch & Cast your LEAN Daily Workout Class or choose a different workout, depending on your mood, using the search. To find a new Guide, please scroll through the Guides content below.`}
        </Text>
        <Box display="flex" flexDirection="row" alignItems="center" className="search-container">
          <SearchOutlined style={{ fontSize: "300%", color: "white" }} />
          <Box position="relative">
            {!!searchQuery && (
              <CloseOutlined
                style={{
                  fontSize: "150%",
                  color: "teal",
                  cursor: "pointer",
                  position: "absolute",
                  right: "1rem",
                  bottom: 0,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                onClick={() => setSearchQuery("")}
              />
            )}
            <Input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e?.target?.value)}
              placeholder={`"${sample(RANDOM_SEARCH_PLACEHOLDERS)}"`}
              color="teal"
              fontSize="1.2rem"
              padding="1rem 1.5rem"
              width="30vw"
              marginLeft="1rem"
            />
          </Box>
        </Box>
      </Box>
      <Box>
        {totalFilteredClassesResults?.length > 0 && searchQuery?.length > 0 && (
          <Text textAlign="center" fontSize="1.2rem" padding="3rem 0 4rem 0">
            {`Found ${totalFilteredClassesResults?.length || 0} classes for your search `}
            <span style={{ fontWeight: 600 }}>{`"${searchQuery}":`}</span>
          </Text>
        )}

        {!searchQuery && guideData?.length > 0 && <MainSliderContainer isGuide title={"Guides"} data={guideData} />}

        <CustomLoader loading={showLoader}>
          <ClassesGridView
            classFilters={classFilters}
            updateClassFilters={updateClassFilters}
            classes={filteredAllClasses}
            showFilterControls={!searching?.length}
            key={!searching?.length}
          />

          {totalFilteredClassesResults?.length === 0 && searchQuery?.length > 0 && (
            <Box padding="2rem 0">
              <Box display="flex" flexDirection="column" alignItems="center">
                <SearchOutlined style={{ fontSize: "400%" }} />

                <Text fontSize={18} fontWeight="400" margin="1rem 2rem 2rem 2rem">
                  {`We could not find any results for "${searchQuery}", why not suggest your idea to us?`}
                </Text>

                <Button width={["100%", "350px"]} marginBottom="3rem" variant="darkTeal">
                  Suggest Idea
                </Button>
              </Box>
            </Box>
          )}
        </CustomLoader>
      </Box>
    </>
  );
};
export default ClassesPage;
