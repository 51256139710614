import React from 'react';
import {Box} from "../../elements/Box";
import {Text} from "../../elements/Text";

export default ({ small, bgColor = 'teal', text, icon, style = { marginTop: '0.5rem' } }) => {
    return (
        <Box style={style}>
            <Box
                padding={small ? '.2rem .4rem' : '.3rem .5rem'}
                backgroundColor={bgColor}
                borderRadius='0.3rem'
                display='inline-flex'
                flexDirection='row'
                alignItems='center'
            >
                {!!icon && icon}
                {
                    !!text && (
                        <Text
                            color='white'
                            fontSize={small ? '0.6rem' : '0.7rem'}
                            fontWeight='600'
                            marginLeft='0.5rem'
                        >{text}</Text>
                    )
                }
            </Box>
        </Box>
    );
};
