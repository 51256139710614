import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { Box } from "../../../../../elements/Box";
import { FcExpand } from "react-icons/fc";
import { Span } from "../../../../../elements/Span";

export default ({ sectionName, rightLabel, defaultOpen = false, children, arrowIconClassName }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggle = () => setIsOpen(!isOpen);

  const expandIconStyle = isOpen
    ? {}
    : {
        transform: "rotate(180deg)",
      };

  return (
    <>
      <Box
        backgroundColor="rgba(0, 0, 0, 0.05)"
        padding="0.5rem"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        onClick={toggle}
      >
        <h5>{sectionName}</h5>
        <Box>
          {!!rightLabel && <Span fontSize=".8rem">{rightLabel}</Span>}
          <FcExpand
            className={arrowIconClassName}
            style={{
              ...expandIconStyle,
              margin: "0 0 -0.1rem 1rem",
              cursor: "pointer",
            }}
          />
        </Box>
      </Box>
      <Collapse isOpened={isOpen}>{children}</Collapse>
    </>
  );
};
