import { useConfig } from '@genflow/core';
import checkSubscriptionRules from "../lib/checkSubscriptionRules";
import { get } from 'lodash';

// Hook to check if the content is locked by checking rules & subscription status
export default (type, val) => {
    const { subscription } = useConfig();
    const hasSubscription = get(subscription, 'hasSubscription')
    if (!hasSubscription) {
        throw new Error('No hasSubscription check provided in config.subscription.')
    }
    const activeSubscription = (typeof hasSubscription === "function") ? hasSubscription() : hasSubscription;
    if (!type) return false;
    const rules = get(subscription, 'rules.lock');
    return !activeSubscription && checkSubscriptionRules(rules, type, val);
};