import React from "react";
import { Router } from "@reach/router";
import PrivateRoute from "../components/PrivateRoute";
import ClassesPage from "../app/classes";
import ClassPage from "../app/class";
import GuidePage from "../app/guide";
import DownloadAppPage from "../app/classes/download";

if (typeof window !== "undefined") {
  // polyfill
  require("scrolling-element");
}

const App = () => (
  <Router basepath="/app">
    {/* <PrivateRoute path="/subscription" component={SubscriptionPage} /> */}
    {/* <PrivateRoute path="/manage-subscription" component={ManageSubscriptionPage} /> */}
    <PrivateRoute path="/classes" component={ClassesPage} />
    <PrivateRoute path="/class/:classAssetID" component={ClassPage} />
    <PrivateRoute path="/guide/:guideProductID" component={GuidePage} />
    {/* <PrivateRoute path="/login" component={LoginPage} /> */}
    <PrivateRoute path="/classes/download" component={DownloadAppPage} />
  </Router>
);
export default App;
