import React, { useEffect, useState } from "react";
import useContentManager from "../hooks/useContentManager";
import { groupBy, orderBy, parseInt } from "lodash";
import transformWorkoutProductToClasses from "../contexts/ContentManager/utils/transformWorkoutProductToClasses";
import ClassesGridView, { CLASS_FILTERS } from "../components/classes/ClassesGridView";
import CustomLoader from "../components/CustomLoader";
import { Box } from "../elements/Box";
import { Image } from "../elements/Image";
import { Text } from "../elements/Text";
import { MdDateRange } from "react-icons/md";

const GuidePage = ({ guideProductID }) => {
  const { guides } = useContentManager();
  const [guide, setGuide] = useState(null);
  const [groupedClasses, setGroupedClasses] = useState([]);

  useEffect(() => {
    const foundGuide = (guides || []).find(({ id }) => parseInt(id) === parseInt(guideProductID));
    const classes = orderBy(
      transformWorkoutProductToClasses(foundGuide),
      [(classItem) => classItem?.subCategory?.toLowerCase(), "dayIndex"],
      ["asc"]
    );
    const weeklyClasses = groupBy(classes, "subCategory");
    setGuide(foundGuide);
    setGroupedClasses(
      Object?.keys(weeklyClasses)?.map((key) => ({
        [key]: weeklyClasses?.[key],
      }))
    );
  }, [guides, guideProductID]);

  const [classFilters, setClassFilters] = useState(CLASS_FILTERS);
  const [classFilterUpdating, setClassFilterUpdating] = useState(false);

  const updateClassFilters = (data) => {
    setClassFilterUpdating(true);
    setClassFilters(data || []);
  };

  const guideBannerImage = guide?.meta_data?.guideLandscapeImage;
  const guideSubtitle = guide?.meta_data?.guideSubtitle;
  const guideDescription = guide?.description?.replace(/\\n/g, "<br />")?.replace("font-family", "disable-font-family");

  const bannerWidth = "40vw";
  const bannerHeight = "16vw";

  return (
    <Box>
      <CustomLoader loading={!guide}>
        <Image
          src={guideBannerImage}
          alt={guide?.name}
          borderRadius=".5rem"
          objectFit="cover"
          boxShadow="0 4px 8px 0 rgba(0,0,0,0.2)"
          // 5:2 ratio
          width={bannerWidth}
          height={bannerHeight}
          display="block"
          margin={"3rem auto 2rem auto"}
        />

        <Box display="flex" justifyContent="center" alignItems="center">
          <MdDateRange size={25} />
          <Text textAlign="center" marginLeft=".5rem" fontWeight="bold">
            {guideSubtitle}
          </Text>
        </Box>
        {!!guideDescription?.length && (
          <Box display="flex" justifyContent="center" alignItems="center" margin="2rem 0">
            <Box
              width={bannerWidth}
              dangerouslySetInnerHTML={{
                __html: guideDescription,
              }}
            />
          </Box>
        )}
      </CustomLoader>
      <CustomLoader loading={classFilterUpdating}>
        <Box>
          {groupedClasses?.map((classes) => (
            <ClassesGridView
              classFilters={classFilters}
              updateClassFilters={updateClassFilters}
              classes={classes}
              isGuideView
              enableCollapse
              splitDays
              totalRows={groupedClasses?.length}
            />
          ))}
        </Box>
      </CustomLoader>
    </Box>
  );
};

GuidePage.propTypes = {};

export default GuidePage;
