import styled from "styled-components";
import { layout, position } from "styled-system";

export const Iframe = styled.iframe`
  ${layout};
  border-radius: ${(props) => props.borderRadius || ""};
  object-fit: "contain";
  ${position}
  border: none;
`;
