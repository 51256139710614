import React from "react";
import { camelCase, startCase } from "lodash";

export default (icon) => {
  if (!icon && typeof icon !== "string") {
    return null;
  }

  if (icon.includes("band")) {
    return "Band";
  }

  return startCase(camelCase(icon)).split(" ").join("-");
};
