import React from "react";
import { Link } from "gatsby";
import { useIsLocked } from "../../utils/freemium";

const LockedContentNavigator = ({ type, id, to: originalTo, children, ...rest }) => {
  const isLocked = useIsLocked(type, id);
  const to = isLocked ? "/app/subscription" : originalTo;
  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
};

export default LockedContentNavigator;
