import React, {useEffect, useState} from "react";
import JoyRide from "react-joyride";
import useStickyState from "../../utils/useStickyState";
import {flatten} from 'lodash';

export const MAIN_CLASS_SCREEN = 'MAIN_CLASS_SCREEN';
export const MAIN_CLASSES_SCREEN = 'MAIN_CLASSES_SCREEN';
const tours = {
    [MAIN_CLASS_SCREEN]: [
        {
            target: `.follow-along-timestamp-0`,
            content: 'Click on a timestamp to swiftly jump to that part of the video!',
        },
        {
            target: `.equipment-item-01`,
            content: 'Here is the equipment list so you can prep before starting.',
        },
        {
            target: '.cast-to-tv',
            content: 'Learn how to cast from any of your devices!',
        },
        {
            target: '.alternative-video-0-0',
            content: "Here you can find alternative videos if you're after something different!",
        },
        {
            target: '.arrow-icon-0',
            content: 'Toggle to compress this group to easily view all the other classes below.',
        },
        {
            target: '.sproutvideo-player',
            content: 'You can adjust the quality, speed or go full screen to take your experience to the next level by clicking/hovering on the video.',
        },
    ],
    [MAIN_CLASSES_SCREEN]: [
        {
            target: `.search-container`,
            content: 'Search anything across all the different class categories with a few taps!',
        },
        {
            target: `.current-guide-container`,
            content: 'This is your current guide that was picked on the app.',
        },
        {
            target: `.search-filter-controls`,
            content: 'Filter any of the various class categories to narrow down your search.',
        },
        {
            target: '.guide-card',
            content: 'Click on a guide to view the complete workout program.',
        },
        // {
        //     target: '.class-card',
        //     content: 'Click on a class to enter the viewing experience.'
        // }
    ],
};
export default ({ screen }) => {
    const screenTours = tours?.[screen] || [];
    const [toursRan, setTourRan] = useStickyState({}, 'toursRan');
    const createScreenTourKey = target => `${screen}-${target}`;

    const joyRideCallback = (data) => {
        if (
            data?.action === 'next'
            && ['running', 'finished'].indexOf(data?.status) > -1
            && data?.type === 'step:after'
            && data?.lifecycle === 'complete'
        ) {
            setTourRan({
                ...toursRan,
                [createScreenTourKey(data?.step?.target)]: true,
            });
        }
    };

    // Filter out completed tours
    const [filteredTours, setFilteredTours] = useState([]);
    useEffect(() => {
        let incompleteTours = screenTours?.map((tour) => toursRan?.[createScreenTourKey(tour?.target)] ? null : tour)
        ?.filter(Boolean);
        setFilteredTours(incompleteTours);
    }, []);

    return (
        <JoyRide
            run
            continuous
            showSkipButton={false}
            steps={(filteredTours || []).map(data => ({ ...data, disableBeacon: true }))}
            callback={joyRideCallback}
            styles={{
                options: {
                    arrowColor: '#fff',
                    backgroundColor: '#fff',
                    overlayColor: 'rgba(0, 0, 0, 0.6)',
                    primaryColor: 'teal',
                    textColor: '#000',
                    zIndex: 1000,
                },
            }}
        />
    );
}
