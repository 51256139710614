import { keys } from "lodash";

// Function to check if an item is locked or not based on it's type,
// rules and value (index or id usually)
export default (rules, type, val) => {
    const ruleIndex = keys(rules).indexOf(type);
    if (ruleIndex < 0) {
        console.warn(`Unknown type provided to useIsLocked: ${type}`);
        return false;
    }
    return rules[type](val);
};