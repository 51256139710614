import React, {useState} from 'react';
import {Collapse} from 'react-collapse';
import {Box} from "../../../elements/Box";
import {FcExpand} from "react-icons/fc";
import {Span} from "../../../elements/Span";
import {Text} from "../../../elements/Text";

export default ({
                    sectionName, rightLabel, defaultOpen = false, children,
                    arrowIconClassName, style,
                }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const toggle = () => setIsOpen(!isOpen);

    const expandIconStyle = isOpen
        ? {}
        : {
            transform: 'rotate(180deg)',
        };

    return (
        <Box style={style}>
            <Box
                backgroundColor='#f0f0f0'
                border={'solid 1px rgba(0, 0, 0, 0.1)'}
                padding='0.5rem'
                display='flex'
                flexDirection='row'
                alignItems='center'
                justifyContent='space-between'
                cursor='pointer'
                margin='0 2vw'
                position='sticky'
                top={0}
                zIndex={30}
                onClick={toggle}>
                <Text
                    fontSize='1.3rem'
                    fontWeight='600'
                    color={isOpen ? 'teal' : 'black'}
                >
                    {sectionName}
                </Text>
                <Box className="video-count">
                    <Span fontSize='.8rem'>{rightLabel}</Span>
                    <FcExpand
                        className={arrowIconClassName}
                        style={{
                            ...expandIconStyle,
                            margin: '0 0 -0.1rem 1rem',
                            cursor: 'pointer',
                        }}
                        color='teal'
                    />
                </Box>

            </Box>
            <Collapse isOpened={isOpen}>
                {isOpen && children}
            </Collapse>
        </Box>
    );
};
