import React from 'react';
import {Box} from "../../../elements/Box";
import {Text} from "../../../elements/Text";
import {Image} from "../../../elements/Image";
import StarFilled from "@ant-design/icons/StarFilled";
import ClassCard from "../ClassCard";
import LazyLoad from 'react-lazy-load';
import Link from "gatsby-link";
import Tag from "../../Tag";
import {useUser} from "../../../hooks/useUser";
import pluralize from 'pluralize';
import useDragDetection from "../../../utils/useDragDetection";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss';
import Arrow from "./components/Arrow";
import CollapsibleSection from "../CollapsibleSection";
import {get, groupBy} from "lodash";
import SliderRowTitle from "../SliderRowTitle";
import {isRestDay} from "../../../contexts/ContentManager/utils/transformWorkoutProductToClasses";
import LockedContentNavigator from '../LockedContentNavigator';
import useContentManager from '../../../hooks/useContentManager';

export default ({
                    title,
                    subTitle,
                    // subTitle = 'This purpose of this content should be written here with strategic commercial messaging.',
                    isCurrentGuide,
                    guideProduct,
                    data,
                    slidesToShow = 6,
                    isGuide,
                    sliderIndex,
                    totalRows,
                    isGuideView,
                    enableCollapse,
                    splitDays,
                }) => {
    const Container = !!sliderIndex && sliderIndex > 1 ? LazyLoad : Box;
    // const Container = Box;

    const { handleMouseDown, dragging } = useDragDetection();

    const {
        currentGuideProductID
    } = useContentManager();

    const MainWrapper = enableCollapse ? CollapsibleSection : Container;

    let wrapperProps = {
        width: '100%',
        margin: '0 auto',
        id: isCurrentGuide && 'current-guide'
            || isGuide && 'guides',
    };

    const sectionName = `${title.toLowerCase().indexOf('week') > -1 ? `${title}${!!totalRows && ` / ${totalRows}`}` : pluralize(title)}`;

    if (enableCollapse) {
        wrapperProps = {
            ...wrapperProps,
            sectionName,
            defaultOpen: sliderIndex === 0,
        };
    }

    let rows = [data];

    if (splitDays) {
        rows = [];

        Object
        ?.values(groupBy(data, 'dayIndex'))
        .forEach(weekData => rows.push(weekData));
    }

    return (
        rows?.length > 0 && (
            <MainWrapper {...wrapperProps}>
                <Box
                    marginBottom={'4rem'}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    className={isCurrentGuide && 'current-guide-container'}
                    backgroundColor={isCurrentGuide ? guideProduct?.meta_data.guideColour : 'white'}
                    padding={isCurrentGuide ? '2rem 0' : 0}
                >
                    {!!title && !isCurrentGuide && (
                        isGuide
                            ? !enableCollapse && (
                            <Text
                                textAlign='center'
                                fontWeight={800}
                                fontSize={'1.8rem'}
                                marginTop='1rem'>
                                Guides
                            </Text>
                        )
                            : !enableCollapse && (
                            <Text
                                style={{
                                    margin: '2rem 2rem 0rem 2rem',
                                    color: isCurrentGuide ? 'white' : 'black',
                                    fontSize: '1.2rem',
                                    fontWeight: 600,
                                }}>
                                {sectionName}
                            </Text>
                        )
                    )}

                    {isCurrentGuide && (
                        <Box position='relative'>
                            {
                                !!guideProduct && (
                                    <Text
                                        style={{
                                            margin: '2rem 2rem 0rem 2rem',
                                            color: isCurrentGuide ? 'white' : 'black',
                                            fontSize: '1.2rem',
                                            fontWeight: 600,
                                        }}>
                                        Current Guide
                                    </Text>
                                )
                            }
                        </Box>
                    )}

                    {!!subTitle && !isGuide && (
                        <Text
                            style={{
                                margin: '1.5rem 2rem 1rem 2rem',
                                color: isCurrentGuide ? 'white' : 'black',
                            }}
                        >{subTitle}</Text>
                    )}

                    {
                        rows?.length > 0 && (
                            rows.map(row => {
                                const groupTitle = isGuideView && `Day ${row?.[0]?.guideDayPosition}`;

                                return (
                                    <Box display='flex' width='100%'
                                         borderBottom={
                                             isGuideView
                                             && totalRows > 0
                                             && 'solid 1px rgba(0, 0, 0, 0.1)'}>
                                        {
                                            !!groupTitle &&
                                            <SliderRowTitle
                                                // whiteFill={isCurrentGuide}
                                                label={groupTitle}
                                            />
                                        }

                                        <Slider
                                            {...{
                                                slidesToShow,
                                                slidesToScroll: 1,
                                                swipe: false,
                                                draggable: false,
                                                arrows: true,
                                                autoplay: isCurrentGuide,
                                                centerMode: false,
                                                lazyLoad: true,
                                                infinite: false,
                                                speed: 300,
                                                prevArrow: <Arrow left/>,
                                                nextArrow: <Arrow right/>,
                                            }}
                                        >
                                            {row?.map((classData) => (
                                                <LockedContentNavigator
                                                    onMouseDownCapture={handleMouseDown}
                                                    onClickCapture={(e) => {
                                                        if (dragging) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    to={!isRestDay(classData) && (isGuide
                                                        ? `/app/guide/${classData?.productId}`
                                                        : `/app/class/${classData?.asset?.id}`)}
                                                    type={isGuide ? 'product' : get(classData, 'lockType')}
                                                    id={isGuide ? parseInt(currentGuideProductID) === parseInt(classData?.productId) : classData?.lockId}
                                                >
                                                    <ClassCard
                                                        key={classData?.asset?.id}
                                                        data={classData}
                                                        isGuide={isGuide}
                                                        isCurrentGuide={isCurrentGuide}
                                                    />
                                                </LockedContentNavigator>
                                            ))}
                                        </Slider>
                                    </Box>
                                );
                            })
                        )
                    }

                </Box>
            </MainWrapper>
        )
    );
}
