import { parseInt } from "lodash";

export default (str) => {
  if (!str || typeof str !== "string") {
    return 0;
  }
  const splitString = str.split(":");

  const h = splitString.length > 2 ? splitString[0] : 0;
  const m = splitString[splitString.length > 2 ? 1 : 0];
  const s = splitString[splitString.length > 2 ? 2 : 1];

  return parseInt(h) * 3600 + parseInt(m) * 60 + parseInt(s);
};
