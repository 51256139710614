import {Box} from "../../../../elements/Box";
import {parseInt} from "lodash";
import CollapsibleSection from "./CollapsibleSection";
import ClassCard from "../../ClassCard";
import React, {useEffect, useState} from "react";
import getTemplateIconName from "../../../../utils/getTemplateIconName";
import TemplateIcon from "../../../../images/TemplateIcon";
import {Text} from "../../../../elements/Text";
import convertStringToSeconds from "../../../../utils/convertStringToSeconds";
import {RiCastLine, RiCheckboxCircleFill, RiPlayFill} from "react-icons/ri";
import Link from "gatsby-link";
import CustomLoader from "../../../CustomLoader";
import JoyRide from 'react-joyride';
import {MAIN_CLASS_SCREEN} from "../../../LEANTour";
import LEANTour from '../../../../components/LEANTour';
import useContentManager from "../../../../hooks/useContentManager";
import useSessionClasses from "../../../../hooks/useSessionClasses";

let intervalRef;

const CURRENT_TIME_REQUEST_INTERVAL = 3;
/*
*  Impact: every 3 seconds check the current time, 1 second felt expensive
*  Caveat: if any timestamp === CURRENT_TIME_REQUEST_INTERVAL then
*  it would start with it pre-selected e.g. 3 === 00:03 pre-selected
* */

export default ({
                    player,
                    width,
                    classObj,
                    alternativeVideos = [],
                    showSectionIndex = false,
                    sessionId,
                }) => {

    // Cleaning out newlines and forced font-family from admin
    const description = classObj?.asset?.description
    ?.replace('font-family', 'disable-font-family')
    ?.replace(/\r\n|\n|\r/gm, '<br />', '<br />');

    const [currentTime, setCurrentTime] = useState();
    const [currentTimeStamp, setCurrentTimeStamp] = useState('');

    const [enableCurrentTimeCheck, setEnableCurrentTimeCheck] = useState(false);

    useEffect(() => {
        // Responsible for updating the currentTime at a rate of CURRENT_TIME_REQUEST_INTERVAL per second
        // This is used for indicating which timestamp the user is on

        if (!player) return;

        if (enableCurrentTimeCheck) {
            intervalRef = setInterval(
                () => {
                    if (!!player) {
                        const playerCurrentTime = player.getCurrentTime();
                        setCurrentTime(playerCurrentTime);
                        setCurrentTimeStamp(classObj
                            ?.asset
                            ?.meta_data
                            ?.timestamps
                            /* Handling lag created due to CURRENT_TIME_REQUEST_INTERVAL
                            by checking CURRENT_TIME_REQUEST_INTERVAL seconds in the future */
                            ?.filter(({ timestamp }) => {
                                return convertStringToSeconds(timestamp) <= parseInt(playerCurrentTime + CURRENT_TIME_REQUEST_INTERVAL);
                            })
                            ?.reverse()?.[0],
                        );
                    }
                },
                CURRENT_TIME_REQUEST_INTERVAL * 1000,
            );
        } else {
            clearInterval(intervalRef);
        }

        // Another optimisation is to disable the interval when the video is not playing
        // OR the user is in full-screen mode as there is no use of the timestamp indicator

        const playHandler = () => setEnableCurrentTimeCheck(true);
        const pauseHandler = () => setEnableCurrentTimeCheck(false);

        // Running on another thread to keep main UI thread secure
        setTimeout(() => {
            player.bind('play', playHandler);
            player.bind('pause', pauseHandler);
        }, 250);


        return () => {
            if (!intervalRef) clearInterval(intervalRef);
            player.unbind('play', playHandler);
            player.unbind('pause', pauseHandler);
        };
    }, [player, enableCurrentTimeCheck]);


    // If this exercise is part of a session in a guide, display the other sessions
    const { getFilteredClasses } = useContentManager();

    // Get other classes in the session
    const sessionClasses = useSessionClasses(sessionId, classObj?.courseTitle);

    const SectionHeader = ({ text }) => (
        <Box
            width='100%'
            backgroundColor='rgba(0, 0, 0, 0.05)'
            padding='0.5rem'
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            marginBottom='1rem'
        >
            <h5>{text}</h5>
        </Box>
    );

    const loading = !classObj || !alternativeVideos?.length;
    const warmUpActivationClasses = sessionClasses?.filter(({ mainGroup }) => ['warmUp', 'activation'].indexOf(mainGroup) > -1);
    const mainClasses = sessionClasses?.filter(({ mainGroup }) => ['main'].indexOf(mainGroup) > -1);
    const finisherCoolDownClasses = sessionClasses?.filter(({ mainGroup }) => ['finisher', 'coolDown'].indexOf(mainGroup) > -1);

    return (
        <Box
            width={width} /* controls the sizing of right/left column */
            // height='200vh'
            paddingRight='1rem'
            // overflow='scroll'
        >
            {!loading && (
                <LEANTour
                    screen={MAIN_CLASS_SCREEN}
                />
            )}

            <Box
                backgroundColor='white'
                width='100%'
                margin='0 auto'
                padding='1rem'
                boxShadow='0 4px 8px 1px rgba(0,0,0,0.2)'
                borderRadius='0.25rem'
            >
                <CustomLoader loading={loading}>
                    <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        marginBottom='1rem'>
                        {!!sessionClasses?.length && (
                            <Link
                                to={`/app/class/${sessionClasses[0]?.asset?.id}`}
                                state={{
                                    sessionPlay: true
                                }}
                            >
                                <Box
                                    className='play-all'
                                    cursor='pointer'
                                    style={{
                                        backgroundColor: 'teal',
                                        color: 'white',
                                        textDecorationLine: 'none',
                                        display: 'inline-flex',
                                        flexDirection: 'row',
                                        padding: '0.5rem 1rem',
                                        borderRadius: '30px',
                                    }}
                                >
                                    <RiPlayFill style={{ marginRight: '0.5rem', fontSize: '150%' }}/>
                                        Autoplay workout
                                </Box>
                            </Link>
                        )}

                        <a
                            href='https://support.leanwithlilly.com/hc/en-us/articles/4402249384466-How-can-I-cast-LEAN-to-my-TV-'
                            target='_blank'
                            className='cast-to-tv'
                            style={{
                                backgroundColor: 'teal',
                                color: 'white',
                                textDecorationLine: 'none',
                                display: 'inline-flex',
                                flexDirection: 'row',
                                padding: '0.5rem 1rem',
                                borderRadius: '30px',
                            }}
                        >
                            <RiCastLine style={{ marginRight: '0.5rem', fontSize: '150%' }}/>
                            Cast To TV
                        </a>
                    </Box>
                    {
                        !!description
                        &&
                        (
                            <Box
                                paddingBottom='1rem'
                                dangerouslySetInnerHTML={{ __html: description }}/>
                        )
                    }

                    {
                        !!sessionClasses?.length
                        &&
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            marginBottom='1rem'
                        >
                            <SectionHeader text={`Today\'s Classes`}/>
                            {
                                !!warmUpActivationClasses?.length && (
                                    <>
                                    <Text
                                        fontSize={14}
                                        width="100%"
                                        fontWeight="600"
                                    >Warm Ups & Activiations</Text>
                                    <Text
                                        fontSize={14}
                                        width="100%"
                                    >We strongly advise you perform a warm up before every class, coupled with an
                                        optional activation.</Text>
                                        </>
                                )
                            }
                            <Box
                                display='flex'
                                flexDirection='row'
                                flexWrap='wrap'
                                justifyContent='space-between'
                                width='100%'
                            >
                                {
                                    warmUpActivationClasses
                                    ?.map((classData) => (
                                        <Link to={`/app/class/${classData?.asset?.id}`} >
                                            <ClassCard
                                                key={classData?.asset?.id}
                                                data={classData}
                                                small
                                                overrideImageProps={{
                                                    flex: 0.4,
                                                }}
                                            />
                                        </Link>
                                    ))
                                }
                            </Box>
                            {
                                !!mainClasses?.length && (
                                    <Text
                                        fontSize={14}
                                        width="100%"
                                        fontWeight="600"
                                    >Main Class</Text>
                                )
                            }
                            <Box
                                display='flex'
                                flexDirection='row'
                                flexWrap='wrap'
                                justifyContent='space-between'
                                width='100%'
                            >
                                {
                                    mainClasses
                                    ?.map((classData) => (
                                        <Link to={`/app/class/${classData?.asset?.id}`} >
                                            <ClassCard
                                                key={classData?.asset?.id}
                                                data={classData}
                                                small
                                                overrideImageProps={{
                                                    flex: 0.4,
                                                }}
                                            />
                                        </Link>
                                    ))
                                }
                            </Box>
                            {
                                !!finisherCoolDownClasses?.length && (
                                    <>
                                    <Text
                                        fontSize={14}
                                        width="100%"
                                        fontWeight="600"
                                    >Cool Downs & Finishers</Text>
                                    <Text
                                        fontSize={14}
                                        width="100%"
                                    >We also strongly advise you perform a finisher after every class, coupled with an
                                        optional cool down to ensure optimal recovery.</Text>
                                        </>
                                )
                            }
                            <Box
                                display='flex'
                                flexDirection='row'
                                flexWrap='wrap'
                                justifyContent='space-between'
                                width='100%'
                            >
                                {
                                    finisherCoolDownClasses
                                    ?.map((classData) => (
                                        <Link to={`/app/class/${classData?.asset?.id}`}>
                                            <ClassCard
                                                key={classData?.asset?.id}
                                                data={classData}
                                                small
                                                overrideImageProps={{
                                                    flex: 0.4,
                                                }}
                                            />
                                        </Link>
                                    ))
                                }
                            </Box>
                        </Box>
                    }

                    {
                        !!classObj?.asset?.meta_data?.equipment
                        &&
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            marginBottom='1rem'
                        >
                            <SectionHeader text='Equipment'/>

                            {
                                classObj?.asset?.meta_data?.equipment?.length === 0
                                && (
                                    <Text
                                        width="100%"
                                    >No equipment needed, just you today!</Text>
                                )
                            }
                            {
                                classObj
                                ?.asset
                                ?.meta_data
                                ?.equipment
                                ?.map((item, index) => (
                                    <Box
                                        width="100%"
                                        display='flex'
                                        flexDirection='row'
                                        alignItems='center'
                                        marginBottom='1rem'
                                        className={`equipment-item-${index}`}
                                    >
                                        <TemplateIcon
                                            icon={getTemplateIconName(item)}
                                            size={30}
                                            color={'teal'}
                                        />
                                        <Text
                                            flex={1}
                                            size={18}
                                            marginLeft='1rem'
                                        >{item}</Text>
                                    </Box>
                                ))
                            }

                        </Box>
                    }

                    {
                        !!classObj?.asset?.meta_data?.timestamps
                        &&
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            marginBottom='1rem'
                        >
                            <SectionHeader text='Follow Along Timestamps'/>
                            {
                                classObj
                                ?.asset
                                ?.meta_data
                                ?.timestamps
                                ?.map((timeStampItem, index) => {
                                    const isActiveTimestamp = currentTimeStamp?.timestamp === timeStampItem?.timestamp;

                                    return (
                                        <Box
                                            width="100%"
                                            display='flex'
                                            flexDirection='row'
                                            alignItems='center'
                                            justifyContent='space-between'
                                            marginBottom='1rem'
                                            cursor='pointer'
                                            onClick={() => {
                                                if (!!player) {
                                                    player.seek(convertStringToSeconds(timeStampItem?.timestamp));
                                                    setCurrentTimeStamp(timeStampItem);
                                                }
                                            }}
                                            pulse={isActiveTimestamp}
                                            className={`follow-along-timestamp-${index}`}
                                        >
                                            {
                                                isActiveTimestamp
                                                && (
                                                    <RiPlayFill style={{
                                                        marginRight: '0.5rem',
                                                        marginTop: '0.2rem',
                                                        color: '#2db3b6',
                                                        fontSize: '150%',
                                                    }}
                                                    />
                                                )
                                            }

                                            <Text
                                                flex={1}
                                                size={18}
                                                fontWeight='550'
                                                color={isActiveTimestamp ? 'teal' : 'black'}
                                                textAlign='left'>{timeStampItem?.label}</Text>
                                            <Text
                                                flex={1}
                                                size={18}
                                                color={isActiveTimestamp ? 'teal' : 'black'}
                                                fontWeight={isActiveTimestamp ? '550' : '400'}
                                                textAlign='right'>{timeStampItem?.timestamp}</Text>
                                            {
                                                convertStringToSeconds(timeStampItem?.timestamp) < currentTime
                                                && (
                                                    <RiCheckboxCircleFill style={{
                                                        marginLeft: '0.5rem',
                                                        marginTop: '0.2rem',
                                                        color: '#2db3b6',
                                                        fontSize: '125%',
                                                    }}
                                                    />
                                                )
                                            }
                                        </Box>
                                    );
                                })
                            }
                        </Box>
                    }

                    <Box key={classObj}>
                        {
                            alternativeVideos
                            .sort(({ mainGroup: mainGroupA }, { mainGroup: mainGroupB }) => {
                                /* Ensuring the top-most alternative video is of the same
                                * mainGroup (or basically "course") and thus more relevant
                                */

                                return classObj?.mainGroup.indexOf(mainGroupB) - classObj?.mainGroup.indexOf(mainGroupA);
                            })
                            .map((section, sectionIndex) => {
                                const sectionVideos = section
                                ?.videos
                                ?.filter(({ id }) => parseInt(id) !== parseInt(classObj?.asset?.id));

                                return (
                                    <CollapsibleSection
                                        sectionName={`${showSectionIndex ? `${sectionIndex + 1}. ` : ''}${section?.name?.replace('Classes Classes', 'Classes')}`}
                                        rightLabel={`${sectionVideos.length} videos`}
                                        defaultOpen={sectionIndex === 0}
                                        arrowIconClassName={`arrow-icon-${sectionIndex}`}
                                    >
                                        <Box
                                            display='flex'
                                            flexDirection='row'
                                            alignItems='center'
                                            justifyContent='space-between'
                                            flexWrap='wrap'
                                        >
                                            {
                                                sectionVideos?.map(({ classObj: classData }, index) => {
                                                    return (
                                                        <Link to={`/app/class/${classData?.asset?.id}`}
                                                              className={`alternative-video-${sectionIndex}-${index}`}
                                                        >
                                                            <ClassCard
                                                                key={classData?.asset?.id}
                                                                data={classData}
                                                                small
                                                                overrideImageProps={{
                                                                    flex: 0.4,
                                                                }}
                                                            />
                                                        </Link>
                                                    );
                                                })
                                            }
                                        </Box>
                                    </CollapsibleSection>
                                );
                            })
                        }
                    </Box>
                </CustomLoader>
            </Box>
        </Box>
    );
}
