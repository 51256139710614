import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Lock from "../../images/icons/locked.svg";
import { Box } from "../../elements/Box";
import { Image } from "../../elements/Image";
import { secureNavigate } from "../../contexts/UserContext";

const SubscriptionOverlayIcon = () => <Image src={Lock} width="40px" alt="Lock" mr={2} />;

SubscriptionOverlayIcon.propTypes = {
  locked: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.number,
};

SubscriptionOverlayIcon.defaultProps = {
  locked: true,
  color: "#fff",
  size: 30,
};

export const navigateSubscribe = (e) => {
  alert("navigateSubscrive");
  secureNavigate(`/app/subscription`);
};

const SubscriptionOverlay = ({ show, safe, style }) => {
  const icon = safe ? (
    <Box>
      <SubscriptionOverlayIcon />
    </Box>
  ) : (
    <SubscriptionOverlayIcon />
  );
  if (!show) return null;
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      flex={1}
      justifyContent="center"
      alignItems="center"
      backgroundColor="rgba(45, 179, 182, 0.4)"
      height="100%"
      width="100%"
      style={style}
      display="flex"
    >
      {icon}
    </Box>
  );
};

SubscriptionOverlay.propTypes = {
  show: PropTypes.bool,
  safe: PropTypes.bool,
};

SubscriptionOverlay.defaultProps = {
  show: false,
  safe: false,
};

export default SubscriptionOverlay;
