import { Box } from "../../elements/Box";
import { Image } from "../../elements/Image";
import PlayCircleOutlined from "@ant-design/icons/PlayCircleOutlined";
import ClockCircleOutlined from "@ant-design/icons/ClockCircleOutlined";
import { Text } from "../../elements/Text";
import React, { useState } from "react";
import formatSecondsToPrettyText from "../../utils/formatSecondsToPrettyText";
import Tag from "../../components/Tag";
import { parseInt, get } from "lodash";
import { isRestDay } from "../../contexts/ContentManager/utils/transformWorkoutProductToClasses";
import { getRandomRestDayQuote } from "../../utils/REST_DAY_QUOTES";
import { useUser } from "../../hooks/useUser";
import { StarFilled } from "@ant-design/icons";
import useContentManager from "../../hooks/useContentManager";
import { useIsLocked } from "../../utils/freemium";
import SubscriptionOverlay from "./SubscriptionOverlay";

export default ({ small, data, isGuide, onClick, overridedimensionsProps = {} }) => {
  const { classTitle, subCategory, asset, isCurrentGuide, productId, isCompletedSession } = data || {};
  const { user } = useUser();
  const { currentGuideProductID } = useContentManager();

  const durationText = asset?.durationSeconds ? formatSecondsToPrettyText(parseInt(asset?.durationSeconds)) : "";

  const width = small ? "12.5vw" : "100%";
  const height = small ? "10vw" : "12vw";

  const dimensionsProps = isGuide
    ? {
        // 3:5 ratio
        width: "15vw",
        height: "25vw",
      }
    : { width, height };

  const restDay = isRestDay(data);
  const [restDayQuote] = useState(getRandomRestDayQuote());
  const isLocked = useIsLocked(get(data, "lockType"), get(data, "lockId"));
  return (
    <Box
      key={classTitle}
      position="relative"
      margin={small ? ".75rem .25rem" : "1rem .5rem"}
      cursor="pointer"
      onClick={onClick}
      className={isGuide ? "guide-card" : "class-card"}
      hoverTransform="scale(115%)"
    >
      {isGuide && parseInt(currentGuideProductID) === parseInt(productId) && (
        <Box
          position="absolute"
          bottom="5.5vw"
          left={0}
          right={0}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Tag
            bgColor="darkPink"
            text={`${user?.attributes?.given_name}'s Current Guide`}
            icon={<StarFilled style={{ fontSize: "80%", color: "white" }} />}
          />
        </Box>
      )}

      {!restDay && (
        <Image
          src={asset?.thumbnail}
          alt={classTitle}
          borderRadius=".5rem"
          objectFit="cover"
          boxShadow="0 4px 8px 0 rgba(0,0,0,0.2)"
          {...dimensionsProps}
          {...overridedimensionsProps}
        />
      )}

      {restDay && (
        <Box {...dimensionsProps} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Text
            color="black"
            fontWeight="900"
            letterSpacing="0.1rem"
            fontSize="1.7rem"
            textTransform="uppercase"
            textAlign="center"
            width="100%"
          >
            Rest Day
          </Text>
          <Text
            color="black"
            fontWeight="300"
            fontSize="1rem"
            textTransform="uppercase"
            textAlign="center"
            width="100%"
          >
            {restDayQuote}
          </Text>
        </Box>
      )}

      {!isGuide && !restDay && (
        <>
          <Box
            position="absolute"
            height="100%"
            bottom={0}
            left={0}
            right={0}
            borderRadius=".5rem"
            padding="0rem 1rem"
            background="linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.1
                            ) 56%, rgba(255,255,255,0) 100%);"
          />
          <Box
            position="absolute"
            bottom="2rem"
            left={0}
            right={0}
            top="1rem"
            margin="0rem .5rem"
            padding="0rem 1rem"
            display="flex"
            flexDirection="column"
            cursor="pointer"
          >
            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
              <PlayCircleOutlined style={{ fontSize: "200%", marginTop: "1rem", color: "teal" }} />
              <Box>
                <Text fontWeight="600" fontSize={small ? "0.9rem" : "0.9rem"} maxWidth="60%" color="black">
                  {classTitle}
                </Text>
                {!!isCurrentGuide && (
                  <Text fontWeight="300" fontSize={small ? "0.7rem" : "0.9rem"} maxWidth="60%" color="black">
                    {/* {isCurrentGuide ? `Day ${data?.guideDayPosition}` : subCategory} */}
                    {subCategory}
                  </Text>
                )}
              </Box>
            </Box>
            {!!durationText && (
              <Tag
                text={durationText}
                icon={<ClockCircleOutlined style={{ fontSize: small ? "80%" : "80%", color: "white" }} />}
              />
            )}
          </Box>
        </>
      )}
      <SubscriptionOverlay show={isLocked} style={{ "border-radius": ".5rem" }} />
    </Box>
  );
};
