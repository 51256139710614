export default (timeSeconds) => {
  let label;
  if (timeSeconds > 60) {
    const minutes = Math.floor(timeSeconds / 60);
    const seconds = timeSeconds % 60;
    label = `${minutes} min`;
    if (minutes > 1) label += "s";
    if (seconds > 0) label += ` ${seconds}s`;
  } else {
    label = timeSeconds > 0 ? `${timeSeconds}s` : "";
  }
  return label;
};
