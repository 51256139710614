import React from "react";
import PropTypes from "prop-types";
import { Box } from "../../elements/Box";
import { Span } from "../../elements/Span";
import theme from "../../theme";

let colourPalette = {};
const DEFAULT_COLOUR_PALETTE_OPTIONS = [
  theme.colors.lilac,
  theme.colors.darkPink,
  theme.colors.teal,
  // theme.colors.pink,
];
let groupColours = [...DEFAULT_COLOUR_PALETTE_OPTIONS];

export default ({ label, whiteFill }) => {
  const getOrCreateColourMatch = (label) => {
    // if (whiteFill) return '#fff';

    if (!colourPalette?.[label]) {
      colourPalette[label] = groupColours.pop();
    }

    if (!groupColours?.length) {
      groupColours = DEFAULT_COLOUR_PALETTE_OPTIONS;
    }

    return colourPalette[`${label}`];
  };

  return (
    <Box
      position="absolute"
      left={0}
      right={0}
      marginTop="1rem"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      width={"100vw"}
      height={"12vw"}
      paddingLeft={"4vw"}
    >
      <Box fontSize="1.3rem">
        <Span color={getOrCreateColourMatch(label)} fontWeight="bold">
          {label}
        </Span>
      </Box>
    </Box>
  );
};
