import React, { useEffect, useState } from "react";
import VideoPage from "../components/classes/VideoPage";
import { useLocation } from "@reach/router";
import useContentManager from "../hooks/useContentManager";
import { Box } from "../elements/Box";

const RANDOM_SEARCH_PLACEHOLDERS = [
  "Back and neck pain ",
  "How to perform a perfect Deadlift",
  "Do I need protein powder?",
  "How do I use my LEAN HIIT band?",
];

let searchDelayTimeout = null;
const ClassPage = ({ classAssetID }) => {
  const { getFilteredClasses, loading, getClassByAssetID } = useContentManager();

  /*
    TODO: Same as TODO on classes.js, we need to fetch the classes on this page via ID,
    TODO: and not by passing the whole object as it prevents direct access to the class detail page (this one)
    TODO: When this happens, we need to run a freemium isLocked check on this screen to prevent URL hacking to bypass freemium locks
    */
  // Using state to trigger rerender if new class requested
  const [classObj, setClassObj] = useState(null);
  useEffect(() => setClassObj(getClassByAssetID(classAssetID)), [classAssetID]);

  const [alternativeVideos, setAlternativeVideos] = useState([]);

  useEffect(() => {
    const filteredClasses = getFilteredClasses({
      config: {
        classFilters: [
          {
            filter: {
              ...(classObj?.productID
                ? { courseProductId: classObj?.productID }
                : { workoutType: classObj?.mainGroup }),
            },
          },
        ],
        includeRestDays: false,
        includeCurrentGuide: false,
      },
    });

    setAlternativeVideos(
      Object?.keys(filteredClasses)?.map((key) => {
        const alternativeClasses = filteredClasses[key];
        const mainGroup = alternativeClasses?.[0]?.mainGroup;
        return {
          id: key,
          name: `${key} Classes`,
          mainGroup,
          videos: alternativeClasses?.map((alternativeCourseObj) => ({
            classObj: alternativeCourseObj,
          })),
        };
      }) || []
    );
  }, [classObj, loading]);

  // Handle autoplaying all session videos
  const location = useLocation();
  const sessionPlay = location?.state?.sessionPlay;

  return (
    <Box paddingBottom="5rem">
      <VideoPage
        key={classObj}
        classObj={classObj}
        loading={loading}
        alternativeVideos={alternativeVideos}
        enableCompleteVideo
        nextButtonLabel="Next"
        onToggleComplete={(video) => {
          // completeLesson(video.id, !video?.complete);
        }}
        mainWidth="70vw"
        sidePanelWidth="30vw"
        sessionPlay={sessionPlay}
      />
    </Box>
  );
};
export default ClassPage;
