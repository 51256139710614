import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import LEANLoadingJSON from "../animations/LottieLoading.json";

const CustomLoader = ({ loading, children }) => (
  <>
    {loading && <Player autoplay loop src={LEANLoadingJSON} style={{ height: "300px", width: "300px" }} />}
    {!loading && !!children && children}
  </>
);

export default CustomLoader;
