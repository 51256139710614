import React, { useState } from "react";
import { flatten, keys, orderBy, values } from "lodash";
import MainSliderContainer from "./MainSliderContainer";
import { Text } from "../../elements/Text";
import { Box } from "../../elements/Box";
import {
  CHALLENGES_PRODUCT_ID,
  FREE_BANDS_EDUCATION_COURSE_PRODUCT_ID,
  LEARN_EXERCISES_COURSE_PRODUCT_ID,
  NUTRITION_COURSE_PRODUCT_ID,
  PHYSIO_COURSE_PRODUCT_ID,
} from "../../config";
import Select from "react-select";
import transformWorkoutProductToClasses from "../../contexts/ContentManager/utils/transformWorkoutProductToClasses";
// workoutTypes
const WARM_UPS = "Warm Ups";
const ACTIVATIONS = "Activations";
const MAIN = "Full Classes"; // Full Classes
const FINISHERS = "Finishers";
const COOL_DOWNS = "Cool downs";

// Dynamically created course filters
const PHYSIO = "Physio Tips";
const LEARN_EXERCISES = "Exercise Fundamentals";
const NUTRITION = "Nutrition Tips";
const BANDS_TUTORIALS = "Bands Tutorials";
const CHALLENGES = "Challenges";

const CLASS_FILTER_TO_OPTION = (classFilter) => ({ label: classFilter?.label, value: classFilter });
const CLASS_FILTER_STYLES = {
  control: (styles) => ({ ...styles, border: 0 }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: "#2db3b6",
  }),

  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
    fontWeight: "600",
  }),

  valueContainer: (styles, state) => ({
    ...styles,
    justifyContent: "center",
  }),
};

export const CLASS_FILTERS = [
  { label: WARM_UPS, filter: { workoutType: "warmUp" } },
  { label: ACTIVATIONS, filter: { workoutType: "activation" } },
  { label: MAIN, filter: { workoutType: "main" } },
  { label: CHALLENGES, filter: { courseProductId: CHALLENGES_PRODUCT_ID } },
  { label: FINISHERS, filter: { workoutType: "finisher" } },
  { label: COOL_DOWNS, filter: { workoutType: "coolDown" } },
  { label: PHYSIO, filter: { courseProductId: PHYSIO_COURSE_PRODUCT_ID } },
  { label: LEARN_EXERCISES, filter: { courseProductId: LEARN_EXERCISES_COURSE_PRODUCT_ID } },
  { label: NUTRITION, filter: { courseProductId: NUTRITION_COURSE_PRODUCT_ID } },
  { label: BANDS_TUTORIALS, filter: { courseProductId: FREE_BANDS_EDUCATION_COURSE_PRODUCT_ID } },
];

export default ({
  isGuideView,
  classes,
  classFilters,
  updateClassFilters,
  showFilterControls,
  enableCollapse,
  splitDays = false,
  totalRows,
}) =>
  keys(classes)?.map((key, index) => {
    const classData = orderBy(flatten(values(classes[key])), "guideDayPosition", ["asc"]);

    const isCurrentGuide =
      classData?.map(({ isCurrentGuide }) => isCurrentGuide)?.filter(Boolean)?.length === classData?.length;

    return (
      <>
        <MainSliderContainer
          key={`${key}-${totalRows}`}
          sliderIndex={index}
          totalRows={totalRows}
          title={key}
          data={classData}
          isCurrentGuide={isCurrentGuide}
          guideProduct={classData?.[0]?.guideProduct}
          isGuideView={isGuideView || isCurrentGuide}
          enableCollapse={enableCollapse}
          splitDays={splitDays}
        />

        {isCurrentGuide && showFilterControls && (
          <>
            <Text textAlign="center" fontWeight={800} fontSize={"1.8rem"} marginTop="1rem" id="all-classes">
              All Classes
            </Text>
            <Box
              // minWidth={''}='10rem'
              margin="1rem auto 0 auto"
              padding="0 10%"
              className="search-filter-controls"
            >
              <Select
                styles={CLASS_FILTER_STYLES}
                closeMenuOnSelect={false}
                isMulti
                options={CLASS_FILTERS?.map(CLASS_FILTER_TO_OPTION)}
                value={classFilters?.map(CLASS_FILTER_TO_OPTION)}
                onChange={(data) => {
                  updateClassFilters(
                    data?.map(({ label }) => CLASS_FILTERS?.find(({ label: filterLabel }) => label === filterLabel))
                  );
                }}
              />
            </Box>
          </>
        )}
      </>
    );
  });
