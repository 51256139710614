import React, { useEffect } from "react";
import { get as _get, keys } from "lodash";
import { Flex } from "../../elements/Flex";
import { Heading } from "../../elements/Heading";
import { Box } from "../../elements/Box";
import { Text } from "../../elements/Text";
import DownloadButtons from "../../components/DownloadButtons";
import { Button } from "../../elements/Button";
import { ExternalLink } from "../../elements/ExternalLink";
import { useUser } from "../../hooks/useUser";
import { Image } from "../../elements/Image";
import { BRAND } from "../../utils/assets";
import { navigate } from "gatsby-link";
import useProfileUserData from "@genflow/web-auth/hooks/useProfileUserData";

const DownloadAppPage = ({}) => {
  return (
    <Flex justifyContent="center" alignItems="center" px={3} minHeight="70vh" py={5} bg="darkTeal">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        backgroundColor="darkTeal"
        padding={"4rem 0"}
        marginBottom="2rem"
      >
        <Box display="flex" flexDirection="row" justifyContent="center" color="white" marginBottom=".5rem">
          <Text fontSize={36} fontWeight="600">{`Welcome to `}</Text>
          <Image src={BRAND.logoWhiteWeb} width="5rem" height="auto" margin="0 1rem" alt={BRAND.name} />{" "}
        </Box>
        <Text fontSize={18} fontWeight="300" marginBottom="2rem" marginTop="2rem" color="white">
          {`Thanks for signing up to LEAN!`}
        </Text>
        <Text fontSize={18} fontWeight="300" marginBottom="2rem" color="white">
          {`To use LEAN Web, please first download the app, log in and set your preferences.`}
        </Text>
        <DownloadButtons />
      </Box>
    </Flex>
  );
};

export default DownloadAppPage;
