import React, { useEffect, useRef, useState } from "react";
import { flatten } from "lodash";
import useStickyState from "../../../utils/useStickyState";
import slugify from "slugify";
import CustomLoader from "../../CustomLoader";
import { Box } from "../../../elements/Box";
import { Text } from "../../../elements/Text";
import { Iframe } from "../../../elements/Iframe";
import pluralize from "pluralize";
import formatSecondsToPrettyText from "../../../utils/formatSecondsToPrettyText";
import useWindowSize from "../../../utils/useWindowSize";
import { Image } from "../../../elements/Image";
import { BRAND } from "../../../utils/assets";
import SidePanel from "./components/SidePanel";
import useContentManager from "../../../hooks/useContentManager";
import useSessionClasses from "../../../hooks/useSessionClasses";
import { navigate } from "gatsby";

const slugifySectionName = (text) =>
  slugify(text, {
    remove: /[*+~.()'"!:@]/g,
  });

// Storing this in the state caused awkward freezing behaviour (even with an alternative thread via setTimeout)
export default ({
  classObj,
  style,
  alternativeVideos = [],
  loading = false,
  showSectionIndex = false,
  mainWidth,
  sidePanelWidth,
  sessionPlay,
}) => {
  const { getFilteredClasses } = useContentManager();
  const [state, setState] = useState({ classObj, alternativeVideos });
  useEffect(() => {
    const filteredClasses = getFilteredClasses({
      config: {
        classFilters: [],
        includeCurrentGuide: true,
        includeRestDays: false,
      },
    });
    delete filteredClasses?.[classObj?.courseTitle];

    setState({
      ...state,
      classObj,
      alternativeVideos,
      filteredClasses,
    });
  }, [classObj, alternativeVideos]);

  const [autoPlay, setAutoPlay] = useStickyState(true, "autoPlay");
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const flatVideosList = flatten(alternativeVideos.map(({ videos }) => videos));

  // Only required for responsive sidebar sizing
  const { width: windowWidth } = useWindowSize();

  const iFrameContainer = useRef();

  // 16:9 ASPECT RATIO MAINTAINED
  const CONTAINER_HORIZONTAL_PADDING = "0vw";

  const timeText = formatSecondsToPrettyText(state?.classObj?.asset?.durationSeconds);
  const timeTextSize = 14;

  const [player, setPlayer] = useState(null);
  useEffect(() => {
    // Create a new player instance when the source changes
    if (playerLoaded && state?.classObj?.asset?.sproutId) {
      if (typeof window !== undefined) {
        const playerInstance = new window.SV.Player({
          videoId: state?.classObj?.asset?.sproutId,
        });

        // Reset video completion state and add completion listener
        setVideoCompleted(false);
        setTimeout(() => {
          playerInstance.bind("completed", function () {
            setVideoCompleted(true);
            this.unbind();
          });

          playerInstance.setQualityLevel(1080);
        }, 250);

        if (sessionPlay) {
          setTimeout(() => {
            playerInstance.play();
            // Persist volume from last video
            if (currentVolume) {
              playerInstance.setVolume(currentVolume);
            }
          }, 500);
        }

        setPlayer(playerInstance);
      }
    }
  }, [playerLoaded, state?.classObj?.asset?.sproutId]);

  // Handle session playing
  const [videoCompleted, setVideoCompleted] = useState(false);

  // Get other classes in the session
  const sessionClasses = useSessionClasses(classObj?.sessionId, classObj?.courseTitle);

  const [currentVolume, setCurrentVolume] = useState();

  // When video completes and sessionPlay is active, go to next video in session
  useEffect(() => {
    if (videoCompleted && sessionPlay) {
      const currentIndex = sessionClasses.findIndex(({ exerciseId }) => exerciseId === classObj?.exerciseId);
      const nextIndex = currentIndex + 1;
      const sessionLength = sessionClasses?.length;

      // Save curret volume setting for next video
      setCurrentVolume(player.getVolume());

      if (nextIndex < sessionLength) {
        navigate(`/app/class/${sessionClasses[nextIndex]?.asset?.id}`, {
          state: {
            sessionPlay: true,
          },
        });
      }
    }
  }, [videoCompleted]);

  console.log("asset url: ", state?.classObj?.asset?.url);

  return (
    <Box style={style}>
      <CustomLoader loading={loading}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          backgroundColor="darkTeal"
          padding={"5rem 0 15rem 0"}
        >
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" color="white">
            <Text fontSize={28} fontWeight="900">
              {state?.classObj?.classTitle}
            </Text>
            <Box display="flex" justifyContent="center" alignItems="center" marginTop=".5rem">
              <Text
                fontSize={timeTextSize}
                fontWeight="500"
                color="white"
                textAlign="center"
                marginRight=".4rem"
              >{`${state?.classObj?.subCategory} `}</Text>
              <Text fontSize={timeTextSize} fontWeight="300" color="white" textAlign="center">{`| ${timeText}`}</Text>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              color="white"
              borderRadius="3rem"
              border="solid 1px white"
              padding="0.2rem 1rem"
              marginTop="1rem"
            >
              <Image src={BRAND.logoWhite} width="2.5rem" height="auto" marginRight=".5rem" alt={BRAND.name} />{" "}
              <Text fontSize={16} fontWeight="400">
                {state?.classObj?.courseTitle || ""}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          margin={`-13rem ${CONTAINER_HORIZONTAL_PADDING}  ${CONTAINER_HORIZONTAL_PADDING}`}
        >
          <Box width={mainWidth} padding="0 1rem">
            <Box width={"100%"} position="relative" paddingBottom={"56.25%"}>
              {!!state?.classObj?.asset?.url && (
                <Iframe
                  id="videoPlayer"
                  ref={iFrameContainer}
                  className="sproutvideo-player"
                  key={`${state?.classObj?.asset?.url}`}
                  src={`${state?.classObj?.asset?.url}`}
                  frameBorder="0"
                  allowFullScreen
                  crossorigin="anonymous"
                  position="absolute"
                  width="100%"
                  height="100%"
                  left={0}
                  top={0}
                  right={0}
                  bottom={0}
                  borderRadius="0.25rem"
                  referrerPolicy="no-referrer-when-downgrade"
                  onLoad={() => setPlayerLoaded(true)}
                />
              )}
            </Box>

            {/*{
                            !!state?.filteredClasses && (
                                <ClassesGridView classes={state?.filteredClasses}/>
                            )
                        }*/}
          </Box>
          <SidePanel
            key={player + classObj}
            player={player}
            height={`${iFrameContainer?.current?.offsetHeight || 0}px`}
            classObj={(!!player && state?.classObj) || []}
            alternativeVideos={(!!player && state?.alternativeVideos) || []}
            showSectionIndex={showSectionIndex}
            width={sidePanelWidth}
            sessionId={classObj?.isCurrentGuide && classObj?.sessionId}
          />
        </Box>
      </CustomLoader>
    </Box>
  );
};
