import React, {useEffect} from 'react';
import {useUser} from "../../hooks/useUser";
// import './style.scss';
import {Box} from "../../elements/Box";
import AppRedirectOnMobiles from "./components/AppRedirectOnMobiles";

export default ({ children }) => {

    console.log('YOU ARE A PRIVATE PAGE SO I AM CHECKING UR SUBS bro');
    const { handleSubscriptionsCheck } = useUser();

    useEffect(handleSubscriptionsCheck, []);

    const className = typeof window !== 'undefined'
        && !window?.location?.pathname !== '/app/login'
        && 'private-page-container';
    return (
        <>
            <Box className={className}>{children}</Box>
            {/*<Box className='mobile-only-container'>*/}
            {/*    <AppRedirectOnMobiles/>*/}
            {/*</Box>*/}
        </>
    );

};


