import React, { useEffect, useMemo } from "react";
import { navigate } from "gatsby";
import PrivatePage from "./PrivatePage";
import useIsLoggedIn from "../utils/auth/useIsLoggedIn";
import useProfileUserData from "@genflow/web-auth/hooks/useProfileUserData";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const authenticated = useIsLoggedIn();
  
    // // IF user is logged in but has no profile, send to download page
    const { data: profileUserData, loading: profileUserDataLoading } = useProfileUserData();

    const hasNoProfile = useMemo(() => {
      return   (profileUserData === undefined || profileUserData?.data === undefined) && profileUserDataLoading === false;
    }, [profileUserDataLoading, profileUserData]);

    console.log('private route', hasNoProfile, profileUserData, profileUserDataLoading);
  
    useEffect(() => {
      // console.log('authenticated', authenticated);
      // console.log('hasNoProfile', hasNoProfile, profileUserData, profileUserDataLoading);
      if (!authenticated || profileUserDataLoading) return;

      const isAppUrl = location.pathname.includes("/app");
      const isDownloadUrl = location.pathname.includes("/app/classes/download");
      if (isAppUrl && hasNoProfile && !isDownloadUrl) {
        console.log('send to download page');
        navigate(`/app/classes/download`);
      } 
      // else if (!hasNoProfile && window?.location?.pathname.includes('download')) {
      //   console.log('send to home page');
      //   // navigate(`/app/classes`);
      // }
      // if (authenticated && hasNoProfile) {
      //   console.log('HASNOPROFILE');
      //   // navigate(`/app/classes/download`);
      // }
    }, [authenticated, hasNoProfile, profileUserDataLoading]);

  return (
    <PrivatePage>
      <Component {...rest} />
    </PrivatePage>
  );
};
export default PrivateRoute;
